import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  ITEM_SERVICE_SECTION: {
    LOADING_MESSAGE: 'Loading',
  },
  SIDEMENU: {
    HOME: 'Página de inicio',
    LIST: 'Lista de vendedores',
    TOTAL_COVERAGE: 'Cobertura total',
  },
  HOME_PAGE: {
    TITLE: 'Gestión de punto de venta',
    LIST: {
      TITLE: 'Lista de puntos de venta',
      DESCRIPTION: 'Consultar toda la lista',
    },
    TOTAL_COVERAGE: {
      TITLE: 'Cobertura total',
      DESCRIPTION: 'Información sobre las áreas que los puntos de venta cubren actualmente.',
    },
  },
  SELLER_LIST_PAGE: {
    TITLE: 'Lista de vendedores',
  },
  TOGGLE_MODAL: {
    TITLE: '¿Cambiar este vendedor a "{status}"?',
    TEXT: 'El status del vendedor <{seller}> se mostrará como "{status}"',
    TOAST: {
      SUCCESS: 'Vendedor ahora en {status}',
      ERROR: 'Error al cambiar vendedor para {status}. Intentar otra vez.',
    },
    OPEN: 'Abierto',
    CLOSE: 'Cerrado',
  },
  STATUS_MODAL: {
    TITLE: 'Cambiar el status para activar',
    TEXT: 'Asignar un centro de distribución para tornar este vendedor en Activo.',
    SELECT_PLACEHOLDER: 'Seleccionar un centro de distribución',
    CANCEL_TITLE: '¿Estás seguro que deseas cancelar?',
    CANCEL_TEXT: 'El status de este vendedor permanecerá igual y no se cambiará a Activo.',
    BACK: 'No, volver',
    QUIT: 'Sí, cancelar',
  },
  TYPE_MODAL: {
    TITLE: 'Activar vendedor',
    TEXT: 'Ahora, asigna un tipo de vendedor a este vendedor. Esta acción es necesaria para cambiar su estado a "activo".',
    SELECT_PLACEHOLDER: 'Selecciona un tipo de vendedor',
    ACTIVATE: 'Activar',
  },
  BUTTONS: {
    GO_BACK: 'Volver atrás',
    BACK: 'Volver',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
    SAVE: 'Save',
    APPLY: 'Aplicar',
    COPY: 'Copiar',
    EDIT: 'Editar',
    SELECT: 'Seleccionar',
    SELECT_OR_EDIT: 'Seleccionar o editar',
    CONTINUE: 'Continue',
    SET_HOURS: 'Configurar',
    ADD_HOURS: 'Agregar horario',
    NEXT: 'Siguiente',
    SET_AREA_DEFAULT: 'Fijar área',
    EDIT_AREA_DEFAULT: 'Editar área',
    SET_COVERAGE_AREA_SCHEDULED: 'Fijar o editar',
    ENABLE: 'Habilitar',
    DISABLE: 'Deshabilitar',
  },
  SELLER_ATTRIBUTES: {
    DISPLAY_NAME: 'Razón Social',
    LEGAL_NAME: 'Nombre legal',
    CONTACTS: 'Contacts',
    PHONE: 'Teléfono',
    EMAIL: 'Email',
    MAIL: 'Email',
    BILLING_ADDRESS: 'Dirección de facturación',
    DELIVERY_ADDRESS: 'Dirección para envío',
    THOROUGHFARE: 'Dirección',
    COMPLEMENT: 'Complemento',
    NUMBER: 'Número',
    NEIGHBORHOOD: 'Barrio',
    REFERENCE: 'Referencia',
    CITY: 'Ciudad',
    STATE: 'Estado',
    ZIP_CODE: 'Código postal',
    COORDINATES: 'Coordenadas',
    LATITUDE: 'Latitud',
    LONGITUDE: 'Longitud',
    STATUS: 'Status',
    ONLINE: 'Activo',
    SELLER_TYPE: 'Tipo de vendedor',
    TAX_ID: 'ID gubernamental',
    CREATED_AT: 'Fecha de creación',
    UPDATED_AT: 'Última actualización',
    FAX: 'Fax',
    SELLER_TYPES: {
      RESTAURANT: 'RestaurantE',
      BAR: 'Bar',
      WINE_HOUSE: 'Licorería',
      CONVENIENCE_STORE: 'Tienda de conveniencia',
      MARKET: 'Mercado',
      GROCERY_STORE: 'Tienda de comestibles',
      BAKERY: 'Panadería',
      DISK_DELIVERY: 'Disk delivery',
      PIT_STOP: 'Pit stop',
      CHOPP_BRAHMA_EXPRESS: 'Chopp Brahma Express',
      DARK_STORE_3RD_PARTY: 'Dark store (terceros)',
      OWNED_STORE: 'Tienda propia',
      OTHERS: 'Otros',
    },
  },
  DELIVERY_CENTER: {
    TITLE: 'Centro de distribución',
    EMPTY:
      'No hay un centro de distribución asignado. Cambia el status del vendedor a Activo para asignarle uno.',
    ATTRIBUTES: {
      ID: 'ID',
      NAME: 'Nombre',
    },
  },
  SELLER_TABLE: {
    PAGINATION: {
      QUANTITY_INDICATOR: 'en',
      PAGE_SIZE_OPTIONS: 'Elementos por página: {options}',
    },
    EMPTY: {
      TITLE: 'No se encontraron vendedores',
      MESSAGE: 'Esta búsqueda no encontró ningún vendedor. Intenta una búsqueda diferente.',
    },
    LOADING: {
      TITLE: 'Cargando',
      MESSAGE: 'Estamos preparando la lista de vendedores para ti.',
    },
    FILTERED: {
      TITLE: 'No hay vendedores aún',
      MESSAGE: 'Los vendedores se mostrarán aquí.',
    },
    ERROR: {
      400: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      401: {
        TITLE: 'Ups, algo falló',
        MESSAGE:
          'Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.',
      },
      403: {
        TITLE: 'Ups, algo falló',
        MESSAGE:
          'Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.',
      },
      404: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'No pudimos llevar a cabo esta acción. Por favor, vuelve a intentar.',
      },
      500: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      502: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
      503: {
        TITLE: 'Ups, algo falló',
        MESSAGE: 'Un error inesperado ocurrió. Por favor, vuelve a intentar.',
      },
    },
  },
  SELLER_DETAILS_PAGE: {
    STORE: 'Tienda',
    OPEN: 'Abierto',
    CLOSED: 'Cerrado',
    GENERAL_INFO: 'Información General',
    EDIT_TOAST: {
      SUCCESS: 'Información del vendedor modificada exitosamente.',
      ERROR: 'Error en la información modificada del vendedor. Intentar otra vez.',
    },
    STATUS_TOAST: {
      SUCCESS: 'Status del vendedor modificado exitosamente.',
      ERROR: 'Error al cambiar el status del vendedor. Intentar otra vez.',
    },
    DELIVERY_CENTER_TOAST: {
      SUCCESS: 'Centro de distribución asignado exitosamente.',
      ERROR: 'Error al asignar el centro de distribución. Intentar otra vez.',
    },
    BUSINESS_HOURS_MESSAGE: 'Para activar a este vendedor, configura el horario de servicio.',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panamá',
    BO: 'Bolivia',
    DO: 'República Dominicana',
    PY: 'Paraguay',
    PE: 'Perú',
    EC: 'Ecuador',
    ZA: 'Sudáfrica',
    CO: 'Colombia',
    MX: 'México',
    AR: 'Argentina',
    BR: 'Brasil',
  },
  VERSION: {
    VERSION_APP: 'Versión',
    SERVER: 'Servidor',
    RIGHTS_RESERVED: 'Anheuser-Busch InBev S.A. Todos los derechos reservados.',
  },
  FILTERS: {
    PLACE_HOLDERS: {
      COUNTRY: 'Seleccione un país',
      DELIVERY_CENTER: 'Todos los centros de distribución',
      STATUS: 'Seleccione un status',
    },
    STATUS: {
      ALL: 'Todos los status',
      ACTIVE: 'Activo',
      PENDING: 'Pendiente',
      INACTIVE: 'Inactivo',
      REJECTED: 'Rechazado',
      BLOCKED: 'Bloqueado',
    },
  },
  FIELDS: {
    OPTIONAL: 'Opcional',
    REQUIRED: {
      GENERIC: 'Campo requerido',
      DISPLAY_NAME: 'Ingresar la Razón Social',
      CITY: 'Ingresar el nombre de la ciudad',
      STATE: 'Ingresar el estado',
      ZIP_CODE: 'Ingresar código postal',
      LATITUTE: 'Ingresar número de latitud',
      LONGITUDE: 'Ingresar número de longitud',
    },
    FORMAT: {
      GENERIC: 'Formato inválido',
      LATITUTE: 'El número de latitud deberá ser entre -90 y 90.',
      LONGITUDE: 'El número de longitud deberá ser entre -180 y 180.',
    },
  },
  UNAUTHORIZED_PAGE: {
    TITLE: 'Ups, acceso denegado.',
    DESCRIPTION: 'Parece que no tienes permiso para acceder a esta página.',
    ACTION: 'Volver a la página de inicio',
  },
  NOT_FOUND_PAGE: {
    HEADER_TEXT: 'Mmm… Parece que esta página se ha ido',
    SUBHEADERTEXT: 'Parece que esta página no se encontró o no existe.',
    BUTTON_TEXT: 'Volver a la página de inicio',
  },
  COVERAGE_AREA: {
    TITLE: 'Área de cobertura',
    SELECTOREDIT: 'Seleccionar o editar área de cobertura',
    HELP_TEXT:
      'Para la ubicación del vendedor, consideramos las coordenadas informadas en la dirección de entrega',
    MAP_CAPTION: {
      TITLE: 'Leyenda del mapa',
      STORE: 'Tienda',
    },
    CREATE_AREA: {
      TITLE: 'Crear un área',
      DESCRIPTION:
        'Crea un área de cobertura dibujando un círculo y ajustando los puntos de edición',
      DESCRIPTION_MULTI_POLYGON:
        'Crea un área de cobertura dibujando un círculo y ajustando los puntos de edición, o seleccione una nueva área de cobertura activa',
      NEW_AREA_ACTION: 'Crea una nueva área',
      CREATE_OR_SELECT_AN_AREA: 'Crear o seleccionar un área',
      SELECT_AN_ACTIVE_COVERAGE_AREA: 'Selecciona un área de cobertura activa',
    },
    EDIT_AREA: {
      TITLE: 'Editar área',
      DESCRIPTION:
        'Cambia el área de cobertura editando su radio o moviendo sus puntos de edición.',
      NAME_INPUT: 'Nombre del área de cobertura',
      NAME_INPUT_PLACEHOLDER: 'Introduce nombre del área',
      NAMEPLACEHOLDER: 'Regular hours',
      NAME_ERROR:
        'Ya tienes un área de cobertura con el mismo nombre. Ingresa uno diferente para continuar',
      EDIT_SELECTED_COVERAGE_AREA: 'Edita el área de cobertura seleccionada',
      EDITING_ACTIONS: {
        TITLE: 'Editar el área usando:',
        RADIUS: 'Radio (km)',
        EDIT_POINTS: 'Editar puntos',
        EDIT_POINTS_DESCRIPTION:
          'Se restablecerá el radio la próxima vez que selecciones esta opción.',
        RADIUS_DESCRIPTION: 'Radio recomendado: 9 km.',
        DELETE_AREA: 'Elimina el área',
      },
      DELETE_COVERAGE_AREA_WITH_AREA_NAME: '¿Quieres borrar <{area}>?',
      DELETE_COVERAGE_AREA: 'Borrar el área de cobertura',
      SELECT_COVERAGE_AREA:
        '¿Quieres que <{area}> se convierta en el área de cobertura activa para este vendedor?',
      CHANGE_ACTIVE_COVERAGE_AREA: 'Cambia el área de cobertura activa',
    },
    EDIT_AREA_DIALOG: {
      TITLE: 'Editar área de cobertura',
      TITLE_SELECT_OR_EDIT: 'Selecciona o edita el área de cobertura',
      SUB_TITLE: 'Debe editar el área de cobertura para que el vendedor pueda activarse.',
    },
    TOAST: {
      SUCCESS: 'Área de cobertura activa modificada correctamente.',
      ERROR: 'No hemos podido cambiar el área de cobertura activa. Vuelve a intentarlo',
    },
  },
  STORE_STATUS: {
    ACTIVE: 'Activo',
    PENDING: 'Pendiente',
    INACTIVE: 'Inactivo',
    REJECTED: 'Rechazado',
    BLOCKED: 'Bloqueado',
  },
  DEFAULT_COVERAGE_AREA: {
    TITLE: 'Área de cobertura predeterminada',
    HELP_TEXT:
      'Essa é a área de entrega principal. Se activará cuando no haya programaciones activas',
    HELP_TEXT_MODAL:
      'Essa é a área de entrega principal. Se activará cuando no haya programaciones activas',
    FORM: {
      TITLE_CREATE: 'Crear un área de cobertura',
      TITLE_EDIT: 'Editar un área de cobertura',
      DESCRIPTION: 'Crea un área de cobertura dibujando un círculo y moviendo para editar.',
      PLACE_HOLDER_NAME: 'Area predeterminada',
      LABEL_NAME: 'Nombre del área',
      TYPE_COORDINATES_LABEL: 'Editar el área usando',
      BUTTON_SAVE: 'Guardar',
      BUTTTON_CANCEL: 'Volver',
    },
    TOAST: {
      SUCCESS_CREATE: 'Área de cobertura creada con éxito.',
      SUCCESS_EDIT: 'Área de cobertura editado con éxito.',
      ERROR: 'No fue posible completar la acción. Inténtalo de nuevo',
    },
    CONFIRM_DEFAULT_COVERAGE_AREA_DIALOG: {
      TITLE: 'Crear área de cobertura predeterminada',
      SUB_TITLE: 'Debes establecer el área de cobertura predeterminada para activar la tienda.',
      BUTTON_CONFIRM: 'Crear area',
      BUTTON_BACK: 'Back',
    },
  },
  SCHEDULED_COVERAGE_AREA: {
    TITLE: 'Área de cobertura programada',
    TITLE_MODAL: 'Área de cobertura programada',
    HELP_TEXT:
      'Define una área de entrega temporal para días y horas específicos. Cuando está inactiva, las entregas se envían a la área principal.',
    HELP_TEXT_MODAL:
      'Define una área de entrega temporal para días y horas específicos. Cuando está inactiva, las entregas se envían a la área principal.',
    EMPTY_CONTENT: 'Este punto de venta aún no tiene áreas programadas.',
    FILLED_CONTENT: 'Crea nuevas áreas con horarios o edita las existentes.',
    FORM: {
      TITLE_CREATE: 'Crear un área de cobertura',
      TITLE_EDIT: 'Editar un área de cobertura',
      DESCRIPTION: 'Crea un área de cobertura dibujando un círculo y moviendo para editar.',
      LABEL_NAME: 'Nombre del área (obligatorio)',
      PLACE_HOLDER_NAME: 'Ej: horario nocturno',
      HELPER_TEXT_NAME: 'El nombre del área no puede modificarse posteriormente.',
      TYPE_COORDINATES_LABEL: 'Editar el área usando',
      BUTTON_SAVE: 'Guardar',
      BUTTTON_CANCEL: 'Volver',
      BUTTON_REMOVE: 'Eliminar área',
      SCHEDULING_LABEL: 'Programación (obligatoria)',
      SCHEDULING_TEXT: 'Gestionar la programación',
      ERRORS: {
        NAME: 'El nombre ya ha sido utilizado en otra área. Por favor, ingrese uno diferente',
      },
    },
    SCHEDULING: {
      TITLE: 'Programación',
      SUB_TITLE: 'Ajustes',
      INFO_TEXT: 'Elige el día y la hora de inicio y fin para programar el área.',
      ADD_TEXT: '+ Añadir nueva fecha y hora',
      DAY_LABEL: 'Día de la semana',
      START_AT_LABEL: 'Hora inicial',
      END_AT_LABEL: 'Hora final',
      ERRORS: {
        ALREADYSELECTED: 'El intervalo de tiempo ya está en otra área de cobertura.',
      },
    },
    TOAST: {
      SUCCESS_CREATE: 'Área de cobertura creada con éxito.',
      SUCCESS_EDIT: 'Área de cobertura editado con éxito.',
      SUCCESS_REMOVE: 'Área de cobertura eliminada con éxito.',
      ERROR: 'No fue posible completar la acción. Inténtalo de nuevo',
    },
    MANAGE_AREA: {
      TITLE: 'Crear un área de cobertura',
      DESCRIPTION: 'Crea un área de cobertura dibujando un círculo y moviendo para editar.',
      BUTTON_CREATE_NEW_AREA: 'Crear nueva área',
      EDIT_AREA_LABEL: 'Elige un área para editar',
      EDIT_SELECTED_AREA_BUTTON_LABEL: 'Editar la zona de cobertura seleccionada',
      DAYS_AND_HOURS_SELECTED_AREA_CARD: {
        TITLE: 'Días y horas en que se activará el área',
        TEXT_BETWEEN_START_AT_AND_FINISH_AT: 'a',
      },
    },
    TOGGLE: {
      TEXT: 'Crea nuevas áreas con horarios o edita las existentes.',
      ENABLE: {
        TITLE: '¿Estás seguro de que quieres habilitar el área programada?',
        FIRST_TEXT: 'Al habilitar esta funcionalidad, el área se indicará con la etiqueta',
        SECOND_TEXT: 'cuando llegue la hora programada.',
      },
      DISABLE: {
        TITLE: '¿Estás seguro de que quieres deshabilitar el área programada?',
        TEXT: 'Al deshabilitar esta funcionalidad, las áreas programadas no se activarán automáticamente cuando comience el tiempo programado.',
      },
    },
    TIMEZONE_INFO: {
      NAME_TITLE: 'Esta tienda sigue',
      NAME_VALUE: 'Time Zone:',
      CURRENT_TIME: 'Hora actual',
    },
  },
  WEEK_DAYS: {
    SUNDAY: 'Domingo',
    MONDAY: 'Lunes',
    TUESDAY: 'Martes',
    WEDNESDAY: 'Miércoles',
    THURSDAY: 'Jueves',
    FRIDAY: 'Viernes',
    SATURDAY: 'Sábado',
  },
  BUSINESS_HOURS: {
    TITLE: 'Horario de servicio',
    EDIT: 'Editar horario de servicio',
    EMPTY:
      'No se ha establecido el horario de servicio. Esta acción es necesaria para activar a este vendedor.',
    OPENS_AT: 'Abre a las',
    CLOSES_AT: 'Cierra a las',
    TOAST: {
      SUCCESS: 'Horario de servicio actualizado.',
      ERROR: 'No se pudo actualizar el horario de servicio. Intenta de nuevo.',
    },
  },
  TOTAL_COVERAGE_PAGE: {
    TITLE: 'Cobertura total',
    FILTERS: {
      FILTERED_BY: 'Filtrado por',
      COUNTRY: 'País',
      FILTER_BUTTON: 'Filtro',
      SEARCH_PLACE_HOLDER: 'Buscar dirección',
      SEARCH_BUTTON: 'Buscar',
      BUTTON_APPLY_FILTER: 'Aplicar',
      BUTTON_CANCEL: 'Cancelar',
      OPERATION_STATUS: 'Estado actual',
      DELIVERY_CENTERS: 'Centros de entrega',
      ALL_DELIVERY_CENTERS: 'Todos los centros de distribución',
    },
    UPDATE_MAP_BUTTON: 'Actualizar mapa',
    MAP_LOADING: {
      TITLE: 'Cargando',
      TEXT: 'Estamos preparando el mapa para ti.',
    },
    OPERATION_STATUS: {
      CLOSED: 'Cerrado',
      OPEN: 'Abierto',
      SHOULD_BE_OPEN: 'Debería estar abierto',
    },
    LAST_UPDATED: 'Última actualización:',
    TOAST_ERROR_LOADING: 'No se pudo cargar tu solicitud. Inténtalo de nuevo.',
    SELLER_DETAILS: {
      PHONE: 'Número de teléfono',
      BUTTON_GO_TO_STORE: 'Mostrar más',
      BUSINESS_HOURS: 'Horario comercial',
      BUSINESS_HOURS_NOT_PROVIDED: 'Horario comercial no proporcionado',
    },
    SELLER_LIST: {
      NUMBER_SELLERS: 'Número de puntos de venta en esta área:',
      SHOW_MORE: '(Mostrar más)',
    },
  },
};

export default enUS;
