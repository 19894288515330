import { useEnvStore } from '@/stores/useEnvStore/useEnvStore';
import { useSellerTotalCoverageFilterStore } from '@/stores/useSellerTotalCoverageFilter/useSellerTotalCoverageFilter';
import { useEffect } from 'react';

const useInitialCountry = () => {
  const { country: countryEnv, setCountry } = useEnvStore(({ country, setCountry }) => ({
    country,
    setCountry,
  }));
  const countryParam = new URLSearchParams(window.location.search).get('country');
  const country = countryParam || countryEnv;

  useEffect(() => {
    if (countryParam && countryParam !== countryEnv) {
      setCountry(countryParam);
      useSellerTotalCoverageFilterStore.setState({ coordinates: undefined });
    }
  }, [countryParam, countryEnv]);

  return country;
};

export default useInitialCountry;
