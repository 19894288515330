import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  TEST: {
    TRANSLATE: 'Translate',
    MISSING: 'Only English option available',
  },
  ITEM_SERVICE_SECTION: {
    LOADING_MESSAGE: 'Loading',
  },
  SIDEMENU: {
    HOME: 'Homepage',
    LIST: 'Sellers list',
    TOTAL_COVERAGE: 'Total Coverage',
  },
  HOME_PAGE: {
    TITLE: 'Seller Management',
    LIST: {
      TITLE: 'Sellers list',
      DESCRIPTION: 'Check all the sellers list',
    },
    TOTAL_COVERAGE: {
      TITLE: 'Total Coverage',
      DESCRIPTION: 'Information about areas that our POCs are currently covering',
    },
  },
  SELLER_LIST_PAGE: {
    TITLE: 'Seller list',
  },
  TOGGLE_MODAL: {
    TITLE: 'Change this seller to "{status}"?',
    TEXT: 'The status of the seller <{seller}> will be set as "{status}"',
    TOAST: {
      SUCCESS: 'Seller successfully changed to {status}',
      ERROR: 'Erro to change seller to {status}. Try again.',
    },
    OPEN: 'open',
    CLOSE: 'close',
  },
  STATUS_MODAL: {
    TITLE: 'Change the status to activate',
    TEXT: 'Assign a delivery center to make this seller active.',
    SELECT_PLACEHOLDER: 'Select a delivery center',
    CANCEL_TITLE: 'Are you sure you want to cancel?',
    CANCEL_TEXT: "The status of this seller will remain the same and it won't change to active.",
    BACK: 'No, go back',
    QUIT: 'Yes, quit',
  },
  TYPE_MODAL: {
    TITLE: 'Activate Seller',
    TEXT: 'Now, assign a seller type to this seller. This action is needed to change its status to “active”.',
    SELECT_PLACEHOLDER: 'Select a seller type',
    ACTIVATE: 'Activate',
  },
  BUTTONS: {
    GO_BACK: 'Go back',
    BACK: 'Back',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    APPLY: 'Apply',
    COPY: 'Copy',
    EDIT: 'Edit',
    SELECT: 'Select',
    SELECT_OR_EDIT: 'Select or Edit',
    CONTINUE: 'Continue',
    SET_HOURS: 'Set hours',
    ADD_HOURS: '+ Add hours',
    NEXT: 'Next',
    SET_AREA_DEFAULT: 'Set area',
    EDIT_AREA_DEFAULT: 'Edit area',
    SET_COVERAGE_AREA_SCHEDULED: 'Set or edit',
    ENABLE: 'Enable',
    DISABLE: 'Disable',
  },
  SELLER_ATTRIBUTES: {
    DISPLAY_NAME: 'Display name',
    LEGAL_NAME: 'Legal name',
    CONTACTS: 'Contacts',
    PHONE: 'Phone',
    EMAIL: 'Email',
    MAIL: 'Email',
    BILLING_ADDRESS: 'Billing address',
    DELIVERY_ADDRESS: 'Delivery address',
    THOROUGHFARE: 'Street address',
    COMPLEMENT: 'Complement (apt, floor, etc)',
    NUMBER: 'Number',
    NEIGHBORHOOD: 'Neighborhood',
    REFERENCE: 'Reference',
    CITY: 'City',
    STATE: 'State',
    ZIP_CODE: 'ZIP/postal code',
    COORDINATES: 'Coordinates',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    STATUS: 'Status',
    ONLINE: 'Online',
    SELLER_TYPE: 'Seller Type',
    TAX_ID: 'Tax ID',
    CREATED_AT: 'Creation date',
    UPDATED_AT: 'Last update',
    FAX: 'Fax',
    SELLER_TYPES: {
      RESTAURANT: 'Restaurant',
      BAR: 'Bar',
      WINE_HOUSE: 'Wine House',
      CONVENIENCE_STORE: 'Convenience Store',
      MARKET: 'Market',
      GROCERY_STORE: 'Grocery Store',
      BAKERY: 'Bakery',
      DISK_DELIVERY: 'Disk Delivery',
      PIT_STOP: 'Pit Stop',
      CHOPP_BRAHMA_EXPRESS: 'Chopp Brahma Express',
      DARK_STORE_3RD_PARTY: 'Dark store (3rd party)',
      OWNED_STORE: 'Owned Store',
      OTHERS: 'Others',
    },
  },
  DELIVERY_CENTER: {
    TITLE: 'Delivery center',
    EMPTY: "No delivery center assigned. Change the seller's status to active to assign one.",
    ATTRIBUTES: {
      ID: 'ID',
      NAME: 'Name',
    },
  },
  SELLER_TABLE: {
    PAGINATION: {
      QUANTITY_INDICATOR: 'of',
      PAGE_SIZE_OPTIONS: 'Items per page: {options}',
    },
    EMPTY: {
      TITLE: 'No Sellers found',
      MESSAGE: "This search didn't find any sellers. Try another one",
    },
    LOADING: {
      TITLE: 'Loading',
      MESSAGE: "We are preparing the seller's list for you.",
    },
    FILTERED: {
      TITLE: 'No Sellers yet',
      MESSAGE: 'The sellers will be displayed here.',
    },
    ERROR: {
      400: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Try again.',
      },
      401: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      403: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'Contact our support team to review your account settings.',
      },
      404: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: "We couldn't perform this action. Try again.",
      },
      500: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Try again.',
      },
      502: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Try again.',
      },
      503: {
        TITLE: 'Oops, something went wrong!',
        MESSAGE: 'An unexpected error ocurred. Please try again.',
      },
    },
  },
  SELLER_DETAILS_PAGE: {
    STORE: 'Store',
    OPEN: 'Open',
    CLOSED: 'Closed',
    GENERAL_INFO: 'General information',
    EDIT_TOAST: {
      SUCCESS: "Seller's information successfully changed.",
      ERROR: 'Error on updated seller info. Try again.',
    },
    STATUS_TOAST: {
      SUCCESS: "Seller's status successfully changed",
      ERROR: 'Error on change seller status. Try again.',
    },
    DELIVERY_CENTER_TOAST: {
      SUCCESS: 'Delivery center successfuly assigned',
      ERROR: 'Error on assign the Delivery center. Try again.',
    },
    BUSINESS_HOURS_MESSAGE: 'To activate this seller, set up  business hours.',
  },
  COUNTRIES: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panama',
    BO: 'Bolivia',
    DO: 'Dominican Republic',
    PY: 'Paraguay',
    PE: 'Peru',
    EC: 'Ecuador',
    ZA: 'South Africa',
    CO: 'Colombia',
    MX: 'Mexico',
    AR: 'Argentina',
    BR: 'Brazil',
  },
  VERSION: {
    VERSION_APP: 'Version',
    SERVER: 'Server',
    RIGHTS_RESERVED: 'Anheuser-Busch Inbev S.A. All rights reserved.',
  },
  FILTERS: {
    PLACE_HOLDERS: {
      COUNTRY: 'Select a country',
      DELIVERY_CENTER: 'All delivery centers',
      STATUS: 'Select a status',
    },
    STATUS: {
      ALL: 'All statuses',
      ACTIVE: 'Active',
      PENDING: 'Pending',
      INACTIVE: 'Inactive',
      REJECTED: 'Rejected',
      BLOCKED: 'Blocked',
    },
  },
  FIELDS: {
    OPTIONAL: 'Optional',
    REQUIRED: {
      GENERIC: 'Required field',
      DISPLAY_NAME: 'Enter a display name',
      CITY: "Enter the city's name",
      STATE: "Enter the state's name",
      ZIP_CODE: 'Enter the ZIP/postal code',
      LATITUTE: 'Enter the latitude number',
      LONGITUDE: 'Enter the longitude number',
    },
    FORMAT: {
      GENERIC: 'Invalid format',
      LATITUTE: 'The latitude number must be between -90 and 90',
      LONGITUDE: 'The longitude number must be between -180 and 180',
    },
  },
  UNAUTHORIZED_PAGE: {
    TITLE: 'Oops, access denied!',
    DESCRIPTION: "It seems you don't have permission to access this page.",
    ACTION: 'Go back to the homepage',
  },
  NOT_FOUND_PAGE: {
    HEADER_TEXT: 'Mmm… Looks like this page is gone',
    SUBHEADERTEXT: "It looks like this page wasn't found or doesn't exist.",
    BUTTON_TEXT: 'Back to home',
  },
  COVERAGE_AREA: {
    TITLE: 'Coverage area',
    SELECTOREDIT: 'Select or edit coverage area',
    HELP_TEXT:
      "For the seller's location, we consider the coordinates informed in the delivey address",
    MAP_CAPTION: {
      TITLE: 'Map caption',
      STORE: 'Store',
    },
    CREATE_AREA: {
      TITLE: 'Create an area',
      DESCRIPTION: 'Create a coverage area by drawing a circle and moving its edit points.',
      DESCRIPTION_MULTI_POLYGON:
        'Create a coverage area by drawing a circle and adjusting the edit points, or select a new active coverage area',
      NEW_AREA_ACTION: 'Create a new area',
      CREATE_OR_SELECT_AN_AREA: 'Create or Select an area',
      SELECT_AN_ACTIVE_COVERAGE_AREA: 'Select an active coverage area',
    },
    EDIT_AREA: {
      TITLE: 'Edit area',
      DESCRIPTION: 'Change the coverage area by drawing a circle and adjusting the edit points',
      NAME_INPUT: 'Coverage area name',
      NAME_INPUT_PLACEHOLDER: 'Enter an area name',
      NAMEPLACEHOLDER: 'Regular hours',
      NAME_ERROR:
        'You already have a coverage area with the same name. Enter a different one to continue.',
      EDIT_SELECTED_COVERAGE_AREA: 'Edit selected coverage area',
      EDITING_ACTIONS: {
        TITLE: 'EDIT THE AREA USING:',
        RADIUS: 'Radius (Km)',
        EDIT_POINTS: 'Editing points',
        EDIT_POINTS_DESCRIPTION:
          'Moving the editing points will reset the radius the next time you select this option.',
        RADIUS_DESCRIPTION: 'RECOMMENDED RADIUS: 9km',
        DELETE_AREA: 'Delete area',
      },
      DELETE_COVERAGE_AREA_WITH_AREA_NAME: 'Do you want to delete <{area}>?',
      DELETE_COVERAGE_AREA: 'Delete coverage area',
      SELECT_COVERAGE_AREA:
        'Do you want <{area}> to become the active coverage area for this seller?',
      CHANGE_ACTIVE_COVERAGE_AREA: 'Change active coverage area',
    },
    EDIT_AREA_DIALOG: {
      TITLE: 'Edit coverage area',
      TITLE_SELECT_OR_EDIT: 'Select or edit coverage area',
      SUB_TITLE: 'You must edit the coverage area so the seller can be activated.',
    },
    TOAST: {
      SUCCESS: 'Active coverage area successfully changed.',
      ERROR: 'We couldn’t change the active coverage area. Try again',
    },
  },
  STORE_STATUS: {
    ACTIVE: 'Active',
    PENDING: 'Pending',
    INACTIVE: 'Inactive',
    REJECTED: 'Rejected',
    BLOCKED: 'Blocked',
  },
  DEFAULT_COVERAGE_AREA: {
    TITLE: 'Default coverage area',
    HELP_TEXT:
      'This is the main delivery area. It will be active when there are no active schedules.',
    HELP_TEXT_MODAL:
      'Main delivery area. While there are no active schedules, the default area will remain active',
    FORM: {
      TITLE_CREATE: 'Create an area',
      TITLE_EDIT: 'Edit an area',
      DESCRIPTION: 'Create a coverage area by drawing a circle and moving its edit points.',
      PLACE_HOLDER_NAME: 'Default coverage area',
      LABEL_NAME: 'Coverage area name',
      TYPE_COORDINATES_LABEL: 'Edit the area using…',
      BUTTON_SAVE: 'Save',
      BUTTTON_CANCEL: 'Back',
    },
    TOAST: {
      SUCCESS_CREATE: 'Default coverage area successfully created',
      SUCCESS_EDIT: 'Default coverage area successfully edited',
      ERROR: 'It was not possible to complete the action. Try again',
    },
    CONFIRM_DEFAULT_COVERAGE_AREA_DIALOG: {
      TITLE: 'Create default area',
      SUB_TITLE: 'You must to set the default area so the store can be activated.',
      BUTTON_CONFIRM: 'Create area',
      BUTTON_BACK: 'Back',
    },
  },
  SCHEDULED_COVERAGE_AREA: {
    TITLE: 'Scheduled area',
    TITLE_MODAL: 'Scheduled coverage area',
    HELP_TEXT:
      'Temporary coverage area with defined days and times. On all other days and times, the store operates with the standard delivery area.',
    HELP_TEXT_MODAL:
      'Set a temporary delivery area for specific days and times. When inactive, deliveries revert to the default coverage area.',
    EMPTY_CONTENT: "This store doesn't have scheduled areas yet",
    FILLED_CONTENT: 'Create new areas with schedules or edit the existing ones.',
    FORM: {
      TITLE_CREATE: 'Create an area',
      TITLE_EDIT: 'Edit an area',
      DESCRIPTION:
        'Create a coverage area with scheduling by drawing a circle, moving its edit points, and choosing days and times for it to be active.',
      LABEL_NAME: 'Coverage area name (required)',
      PLACE_HOLDER_NAME: 'Enter an area name',
      HELPER_TEXT_NAME: 'The name of the area cannot be changed later.',
      TYPE_COORDINATES_LABEL: 'Edit the area using…',
      BUTTON_SAVE: 'Save',
      BUTTTON_CANCEL: 'Back',
      BUTTON_REMOVE: 'Delete area',
      SCHEDULING_LABEL: 'Scheduling (required)',
      SCHEDULING_TEXT: 'Manage scheduling',
      ERRORS: {
        NAME: 'The name has already been used in another area.',
      },
    },
    SCHEDULING: {
      TITLE: 'Scheduling',
      SUB_TITLE: 'Settings',
      INFO_TEXT: 'Select the day and start and end times to schedule the area',
      ADD_TEXT: '+ Add new date and time',
      DAY_LABEL: 'Day of the week',
      START_AT_LABEL: 'Start time',
      END_AT_LABEL: 'End time',
      ERRORS: {
        ALREADYSELECTED: 'That time period is already scheduled for another area',
      },
    },
    TOAST: {
      SUCCESS_CREATE: 'Scheduled coverage area successfully created',
      SUCCESS_EDIT: 'Scheduled coverage area successfully edited',
      SUCCESS_REMOVE: 'Scheduled coverage area successfully deleted',
      ERROR: 'It was not possible to complete the action. Try again',
    },
    MANAGE_AREA: {
      TITLE: 'Create an area',
      DESCRIPTION:
        'Create a coverage area with scheduling by drawing a circle, moving its edit points, and choosing days and times for it to be active.',
      BUTTON_CREATE_NEW_AREA: 'Create a new area',
      EDIT_AREA_LABEL: 'Select an area to edit',
      EDIT_SELECTED_AREA_BUTTON_LABEL: 'Edit selected coverage area',
      DAYS_AND_HOURS_SELECTED_AREA_CARD: {
        TITLE: 'Days and times when the area will be activated',
        TEXT_BETWEEN_START_AT_AND_FINISH_AT: 'to',
      },
    },
    TOGGLE: {
      TEXT: 'Create new areas with schedules or edit the existing ones.',
      ENABLE: {
        TITLE: 'Are you sure you want to enable the scheduled area?',
        FIRST_TEXT: 'By enabling this functionality, the area will be indicated with the tag',
        SECOND_TEXT: 'when the scheduled time arrives.',
      },
      DISABLE: {
        TITLE: 'Are you sure you want to disable the scheduled area?',
        TEXT: "By disabling this functionality, scheduled areas won't  be automatically activated when the scheduled time starts.",
      },
    },
    TIMEZONE_INFO: {
      NAME_TITLE: 'This store belongs to',
      NAME_VALUE: 'Time Zone:',
      CURRENT_TIME: 'Current Time',
    },
  },
  WEEK_DAYS: {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
  },
  BUSINESS_HOURS: {
    TITLE: 'Business hours',
    EDIT: 'Edit business hours',
    EMPTY: "Business hours haven't been set. This action is needed to activate this seller.",
    OPENS_AT: 'Opens at',
    CLOSES_AT: 'Closes at',
    TOAST: {
      SUCCESS: 'Business hours updated.',
      ERROR: 'Unable to update business hours. Try again.',
    },
  },
  TOTAL_COVERAGE_PAGE: {
    TITLE: 'Total coverage',
    FILTERS: {
      FILTERED_BY: 'Filtered by',
      COUNTRY: 'Country',
      FILTER_BUTTON: 'Filter',
      SEARCH_PLACE_HOLDER: 'Search address',
      SEARCH_BUTTON: 'Search',
      BUTTON_APPLY_FILTER: 'Apply',
      BUTTON_CANCEL: 'Cancel',
      OPERATION_STATUS: 'Current Status',
      DELIVERY_CENTERS: 'Delivery Centers',
      ALL_DELIVERY_CENTERS: 'All delivery centers',
    },
    UPDATE_MAP_BUTTON: 'Update map',
    MAP_LOADING: {
      TITLE: 'Loading',
      TEXT: 'We are preparing the map for you.',
    },
    OPERATION_STATUS: {
      CLOSED: 'Closed',
      OPEN: 'Open',
      SHOULD_BE_OPEN: 'Should be open',
    },
    LAST_UPDATED: 'Last updated:',
    TOAST_ERROR_LOADING: 'Unable to load your request. Please try again',
    SELLER_DETAILS: {
      PHONE: 'Phone',
      BUTTON_GO_TO_STORE: 'Go to store',
      BUSINESS_HOURS: 'Business hours',
      BUSINESS_HOURS_NOT_PROVIDED: 'Business hours not provided',
    },
    SELLER_LIST: {
      NUMBER_SELLERS: 'Number of points of sale in this area:',
      SHOW_MORE: '(go to the store)',
    },
  },
};

export default enUS;
