import { defaultDevtoolsOptions } from '@/config/stores';
import { EnvConfig } from '@/types/common.types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { useSellerListFilterStore } from '../useSellerListFilterStore/useSellerListFilterStore';
import { useSellerTotalCoverageFilterStore } from '../useSellerTotalCoverageFilter/useSellerTotalCoverageFilter';

export interface EnvConfigParams extends EnvConfig {
  country: string;
  defaultCountry?: string;
  authorizedCountries?: string[];
}

const initialState: EnvConfigParams = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  supportedCountries: [],
  segmentKey: '',
  vendorId: {},
  az_maps_account_client_id: '',
  az_maps_account_primary_key: '',
  optimizely_key: '',
  google_maps_key: '',
  defaultCountry: '',
  country: '',
  authorizedCountries: [],
};

export interface IEnvStore extends EnvConfigParams {
  setEnv: (env: Omit<EnvConfigParams, 'country'>) => void;
  setCountry: (country: EnvConfigParams['country']) => void;
  setAuthorizedCountry: (authorizedCountries: EnvConfigParams['authorizedCountries']) => void;
}

const persistOptions = {
  name: 'sellerEnvValues',
  storage: createJSONStorage(() => localStorage),
};

export const useEnvStore = create<IEnvStore>()(
  persist(
    devtools(
      (set, get) => ({
        ...initialState,
        setEnv: (env) => set({ ...env, country: get().country || env.defaultCountry }),
        setCountry: (country) => {
          if (get().country !== country) {
            useSellerListFilterStore.setState({ deliveryCenterGroupId: '' });
            useSellerTotalCoverageFilterStore.setState({ deliveryCenter: '' });
          }
          set({ country });
        },
        setAuthorizedCountry: (authorizedCountries) => set({ authorizedCountries }),
      }),
      { ...defaultDevtoolsOptions, anonymousActionType: 'useEnvStore' }
    ),
    persistOptions
  )
);
