import FooterVersion from '@/components/FooterVersion/FooterVersion';
import useSidebar from '@/hooks/useSidebar/useSidebar';
import Router from '@/utils/Router/Router';
import { GridContainer, SideBar } from './AppBarConfig.styles';

const AppBarConfig = (): JSX.Element => {
  useSidebar();

  return (
    <SideBar>
      <GridContainer sidebar type="fluid">
        <Router />
        <FooterVersion />
      </GridContainer>
    </SideBar>
  );
};

export default AppBarConfig;
