import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@hexa-ui/theme';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { BrowserRouter } from 'react-router-dom';
import { getApolloClient } from './Api/graphql/graphql';
import { FEATURE_TOGGLE_V2_PROJECT_NAME } from './config/featureToggles';
import './global.styles.css';
import useInitialCountry from './hooks/useInitialCountry/useInitialCountry';
import useLoadEnvs from './hooks/useLoadEnvs/useLoadEnvs';
import { IntlProvider } from './i18n';
import ToastProvider from './providers/ToastProvider/ToastProvider';
import { EnvConfig } from './types/common.types';
import AppBarConfig from './utils/AppBarConfig';

const App = (props: EnvConfig): JSX.Element => {
  const { optimizely_key } = props;
  createFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME, optimizely_key || '');
  const country = useInitialCountry();
  useLoadEnvs(props);
  const client = getApolloClient(country);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme="tadaPrimary">
        <IntlProvider>
          <BrowserRouter>
            <ToastProvider>
              <AppBarConfig />
            </ToastProvider>
          </BrowserRouter>
        </IntlProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
