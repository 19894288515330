import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const SideBar = styled('div', {
  fontFamily: 'Work Sams Medium',
  fontSize: '14px',
});

export const GridContainer = styled(Grid.Container, {
  rowGap: '1rem',
  display: 'flex',
  height: 'calc(-3.5rem + 100vh)',
  flexDirection: 'column',
  flexWrap: 'nowrap',
});
