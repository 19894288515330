import { NAME_DEFAULT } from '@/config/constants';
import { EnvConfig } from '@/types/common.types';
import { ISellerList, ISellerProps } from '@/types/seller.types';

export const sellerResponseMock: ISellerProps = {
  id: '4c08a2ec-ba63-4993-8c93-c62d3b14f7c6',
  displayName: 'Jane Doe Restaurant',
  legalName: 'Jane Doe Restaurant LLC',
  isDynamicCoverageAreaToggleOn: false,
  isScheduleEnable: false,
  contacts: [
    {
      type: 'PHONE',
      value: '+13458675309',
    },
    {
      type: 'EMAIL',
      value: 'foo@bar.com',
    },
  ],
  billingAddress: {
    lines: [
      {
        type: 'THOROUGHFARE',
        value: 'Flowers Street',
      },
      {
        type: 'COMPLEMENT',
        value: 'Apartment B',
      },
      {
        type: 'NUMBER',
        value: '100',
      },
      {
        type: 'NEIGHBORHOOD',
        value: 'Chestnut Street District',
      },
      {
        type: 'REFERENCE',
        value: 'Next to the TA gas station',
      },
    ],
    city: 'Campinas',
    state: 'SP',
    zipcode: '13010010',
    latitude: '-229064',
    longitude: '-47.0616',
  },
  deliveryAddress: {
    lines: [
      {
        type: 'THOROUGHFARE',
        value: 'Flowers Street',
      },
      {
        type: 'COMPLEMENT',
        value: 'Apartment B',
      },
      {
        type: 'NUMBER',
        value: '100',
      },
      {
        type: 'NEIGHBORHOOD',
        value: 'Chestnut Street District',
      },
      {
        type: 'REFERENCE',
        value: 'Next to the TA gas station',
      },
    ],
    city: 'Campinas',
    state: 'SP',
    zipcode: '13010010',
    latitude: '-229064',
    longitude: '-47.0616',
  },
  coverageArea: [
    {
      name: NAME_DEFAULT,
      active: true,
      coordinates: [
        [-89.20734317966023, 13.697989876149492],
        [-89.20651740290847, 13.698612165245962],
        [-89.20790014433915, 13.69880360897308],
        [-89.20734317966023, 13.697989876149492],
      ],
    },
    {
      name: 'Other Coverage Area',
      active: false,
      coordinates: [
        [-89.20975940476421, 13.695919261791161],
        [-89.2078104467376, 13.696235059167705],
        [-89.20871817543998, 13.696962291445502],
        [-89.21102975404781, 13.696527607763628],
        [-89.2112989248829, 13.695831779578938],
        [-89.20975940476421, 13.695919261791161],
      ],
    },
  ],
  additionalInformation: {
    issuesInvoices: true,
    refrigeratorCount: 'ZERO',
    hasColdRoom: false,
    hasServiceEntry: false,
  },
  businessHours: {
    sunday: [
      {
        openAt: '1970-01-01T09:00:00.000Z',
        closeAt: '1970-01-01T21:21:00.000Z',
      },
    ],
    monday: [
      {
        openAt: '1970-01-01T09:00:00.000Z',
        closeAt: '1970-01-01T21:21:00.000Z',
      },
    ],
    tuesday: [
      {
        openAt: '1970-01-01T09:00:00.000Z',
        closeAt: '1970-01-01T21:21:00.000Z',
      },
    ],
    wednesday: [
      {
        openAt: '1970-01-01T09:00:00.000Z',
        closeAt: '1970-01-01T21:21:00.000Z',
      },
    ],
    thursday: [
      {
        openAt: '1970-01-01T09:00:00.000Z',
        closeAt: '1970-01-01T21:21:00.000Z',
      },
    ],
    friday: [
      {
        openAt: '1970-01-01T09:00:00.000Z',
        closeAt: '1970-01-01T21:21:00.000Z',
      },
    ],
    saturday: [
      {
        openAt: '1970-01-01T09:00:00.000Z',
        closeAt: '1970-01-01T21:21:00.000Z',
      },
    ],
  },
  status: 'PENDING',
  online: true,
  segment: 'RESTAURANT',
  taxId: '47406274000149',
  deliveryCenterGroupId: '',
  creatorId: 'c6223b38-a6bf-4d86-8fdf-18b3de322676',
  createdAt: '2022-08-17T21:08:34.202Z',
  updatedAt: '2022-08-17T21:08:34.202Z',
  timezone: 'America/Sao_Paulo',
};

export const emptySellerTableMock: Array<any> = [
  {
    id: '4c08a2ec-ba63-4993-8c93-c62d3b14f7c6',
    legalName: '',
    deliveryAddress: {
      lines: [
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
      ],
      city: '',
      state: '',
      zipcode: '',
      latitude: '-229064',
      longitude: '-47.0616',
    },
    billingAddress: {
      lines: [
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
      ],
      city: '',
      state: '',
      zipcode: '',
      latitude: '-229064',
      longitude: '-47.0616',
    },
    businessHours: {
      sunday: [
        {
          openAt: '1970-01-01T09:00:00.000Z',
          closeAt: '1970-01-01T21:21:00.000Z',
        },
      ],
      monday: [
        {
          openAt: '1970-01-01T09:00:00.000Z',
          closeAt: '1970-01-01T21:21:00.000Z',
        },
      ],
      tuesday: [
        {
          openAt: '1970-01-01T09:00:00.000Z',
          closeAt: '1970-01-01T21:21:00.000Z',
        },
      ],
      wednesday: [
        {
          openAt: '1970-01-01T09:00:00.000Z',
          closeAt: '1970-01-01T21:21:00.000Z',
        },
      ],
      thursday: [
        {
          openAt: '1970-01-01T09:00:00.000Z',
          closeAt: '1970-01-01T21:21:00.000Z',
        },
      ],
      friday: [
        {
          openAt: '1970-01-01T09:00:00.000Z',
          closeAt: '1970-01-01T21:21:00.000Z',
        },
      ],
      saturday: [
        {
          openAt: '1970-01-01T09:00:00.000Z',
          closeAt: '1970-01-01T21:21:00.000Z',
        },
      ],
    },
    coverageArea: [],
    status: '',
    online: false,
    segment: '',
    taxId: '',
    deliveryCenterGroupId: '',
    createdAt: '',
  },
];

export const sellerListMock: ISellerList = {
  content: [
    {
      id: '4c08a2ec-ba63-4993-8c93-c62d3b14f7c6',
      displayName: 'Jane Doe Restaurant',
      legalName: 'Jane Doe Restaurant LLC',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+13458675309',
        },
        {
          type: 'EMAIL',
          value: 'foo@bar.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Flowers Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '100',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Campinas',
        state: 'SP',
        zipcode: '13010010',
        latitude: '-229064',
        longitude: '-47.0616',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Flowers Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '100',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Campinas',
        state: 'SP',
        zipcode: '13010010',
        latitude: '-229064',
        longitude: '-47.0616',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'PENDING',
      online: false,
      segment: 'RESTAURANT',
      taxId: '47406274000149',
      deliveryCenterGroupId: '62ff8f6cfa7a9c7e4a744282',
      creatorId: 'c6223b38-a6bf-4d86-8fdf-18b3de322676',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: '582f3c86-ec68-47c8-ac25-a6c8d00912d1',
      displayName: 'Talkalot',
      legalName: 'Mathews Chase Parrish',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(806) 450-2059',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@fitcore.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Bushwick Avenue',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '385',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Coles Street',
        state: 'SP',
        zipcode: '23485175',
        latitude: '47.936824',
        longitude: '-126.078468',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Verona Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9188',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Duryea Court',
        state: 'MG',
        zipcode: '18577898',
        latitude: '-50.366784',
        longitude: '-159.937757',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'PENDING',
      online: false,
      segment: 'EVIDENDS',
      taxId: '63286af2fa44332860beefab',
      deliveryCenterGroupId: '63286af20fcc30e8be1ed45c',
      creatorId: '15e63b80-4a06-4a46-be3f-479082edf5c4',
      createdAt: '2022-04-23T06:51:32.202Z',
      updatedAt: '2022-01-12T03:24:19.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: '39f5bda3-45c0-4b9e-aeca-d4ed8a8f28c2',
      displayName: 'Twiist',
      legalName: 'Merle Acosta Hill',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(837) 537-3189',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@vendblend.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Pierrepont Place',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9157',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Kent Avenue',
        state: 'TO',
        zipcode: '38929793',
        latitude: '49.690588',
        longitude: '-108.498289',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Junius Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '8711',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Noble Street',
        state: 'MA',
        zipcode: '29039848',
        latitude: '22.965057',
        longitude: '10.878615',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'ACTIVE',
      online: true,
      segment: 'ELENTRIX',
      taxId: '63286af2cc49fee248ac2f45',
      deliveryCenterGroupId: '62ff8f6cfa7a9c7e4a744282',
      creatorId: '9d011562-929b-40d4-8613-3251b55bee36',
      createdAt: '2021-02-04T03:25:07.202Z',
      updatedAt: '2022-01-19T02:42:36.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: '34c743c6-0f0f-46f0-b539-d6825a63510e',
      displayName: 'Sustenza',
      legalName: 'Salinas Acevedo Frazier',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(838) 591-3784',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@shadease.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Gaylord Drive',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '3180',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Dwight Street',
        state: 'CO',
        zipcode: '20020480',
        latitude: '-47.729474',
        longitude: '-136.411744',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Moffat Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '8002',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Knight Court',
        state: 'XY',
        zipcode: '33266397',
        latitude: '-8.325235',
        longitude: '-61.263826',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'BLOCKED',
      online: false,
      segment: 'BYTREX',
      taxId: '63286af2c0c85a77db013963',
      deliveryCenterGroupId: '62ff8f6cfa7a9c7e4a744282',
      creatorId: 'e5b5befb-fc19-487e-b62a-aa3bbd567375',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: '3a9751f8-6122-4d5a-99db-bfc3360cbd69',
      displayName: 'Radiantix',
      legalName: 'Peggy Hart Moody',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(974) 412-3487',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@brainquil.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Rewe Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '5272',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Thatford Avenue',
        state: 'TZ',
        zipcode: '13599998',
        latitude: '50.319835',
        longitude: '78.008358',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Lancaster Avenue',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9560',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Green Street',
        state: 'RJ',
        zipcode: '46033270',
        latitude: '23.567029',
        longitude: '116.735659',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'PENDING',
      online: false,
      segment: 'COMTRAIL',
      taxId: '63286af22ad54ac0c98c80e9',
      deliveryCenterGroupId: '63286af205520fbfd84946ca',
      creatorId: 'b7e84936-f1d1-4ee4-b31b-ad66b76b053f',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: '536edae9-5e35-4eb8-b645-706cd0aadc57',
      displayName: 'Colaire',
      legalName: 'Katelyn Fernandez Mclaughlin',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(846) 486-3515',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@geekosis.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Hoyt Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '4409',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Baycliff Terrace',
        state: 'GO',
        zipcode: '63980494',
        latitude: '-38.337704',
        longitude: '101.368669',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Will Place',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9260',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'River Street',
        state: 'SA',
        zipcode: '34606823',
        latitude: '79.419143',
        longitude: '43.698125',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'INACTIVE',
      online: false,
      segment: 'CORPULSE',
      taxId: '63286af272ade6fbf0895d1a',
      deliveryCenterGroupId: '62ff8f6cfa7a9c7e4a744282',
      creatorId: '7c5aca77-c6f4-4b3c-9bf4-6b6682e75f7a',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: 'bf17501b-4dff-4a9d-b861-7234f3b17cd9',
      displayName: 'Stralum',
      timezone: 'America/Sao_Paulo',
      legalName: 'Chavez Wilkins Hernandez',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(808) 592-2933',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@insuron.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Doscher Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '3407',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Veronica Place',
        state: 'QA',
        zipcode: '81864021',
        latitude: '-16.228055',
        longitude: '147.962726',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Hunterfly Place',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '6561',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Lincoln Terrace',
        state: 'PQ',
        zipcode: '54197999',
        latitude: '-23.407501',
        longitude: '0.07297',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'REJECTED',
      online: false,
      segment: 'LYRICHORD',
      taxId: '63286af2071a5a9521333518',
      deliveryCenterGroupId: '62ff8f6cfa7a9c7e4a744282',
      creatorId: '7b7d1e40-f278-4699-a905-99b835575fc8',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
    },
    {
      id: '116ca46b-7575-4cb0-ac45-3be59311e07e',
      displayName: 'Motovate',
      legalName: 'Phillips Monroe Wood',
      isDynamicCoverageAreaToggleOn: false,
      timezone: 'America/Sao_Paulo',
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(897) 424-3446',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@mangelica.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Front Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '3093',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Tampa Court',
        state: 'XZ',
        zipcode: '22632893',
        latitude: '-63.768314',
        longitude: '55.025396',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Grattan Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9305',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Prince Street',
        state: 'DC',
        zipcode: '89288509',
        latitude: '-43.953804',
        longitude: '-21.698222',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'ACTIVE',
      online: true,
      segment: 'NETAGY',
      taxId: '63286af2ede3cbc91b707643',
      deliveryCenterGroupId: '63286af249d593ece7d4b2e6',
      creatorId: '7079579c-9ad1-49b7-be2b-adf55bdcc91a',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
    },
    {
      id: '5ef53296-2feb-4efc-9b09-3cdcf562561b',
      displayName: 'Xplor',
      legalName: 'Jordan Slater Peterson',
      isDynamicCoverageAreaToggleOn: false,
      timezone: 'America/Sao_Paulo',
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(907) 578-3954',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@cincyr.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Kaufman Place',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '3962',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Madoc Avenue',
        state: 'WG',
        zipcode: '82884326',
        latitude: '-14.799424',
        longitude: '53.96073',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Liberty Avenue',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '6792',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Beadel Street',
        state: 'ML',
        zipcode: '40746721',
        latitude: '45.455011',
        longitude: '-113.20263',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'REJECTED',
      online: false,
      segment: 'VALREDA',
      taxId: '63286af2a6df3ebb63f2bca1',
      deliveryCenterGroupId: '63286af299b3a08713f57dd1',
      creatorId: '6dad5e55-b326-4e6a-8e48-1d0d84fc325c',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
    },
    {
      id: '7869e906-2a55-474e-a083-3d6c3a4b786a',
      displayName: 'Krag',
      legalName: 'Gayle Best Strong',
      isDynamicCoverageAreaToggleOn: false,
      timezone: 'America/Sao_Paulo',
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(802) 541-2823',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@comtent.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Rutledge Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9195',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Aurelia Court',
        state: 'NI',
        zipcode: '52613335',
        latitude: '40.204186',
        longitude: '-1.007511',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Lenox Road',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '8823',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Ralph Avenue',
        state: 'PO',
        zipcode: '86664452',
        latitude: '82.603195',
        longitude: '-10.703325',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'BLOCKED',
      online: false,
      segment: 'CAXT',
      taxId: '63286af25815d5f4234d0db0',
      deliveryCenterGroupId: '63286af299e9efe3ea3c15c8',
      creatorId: '4cbebe77-bbc3-4544-b133-397dc1a6421f',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
    },
    {
      id: '81cd8599-d297-4234-a414-84990f71320f',
      displayName: 'Imaginart',
      legalName: 'Tricia Maldonado Whitfield',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(844) 546-2605',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@dentrex.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Cornelia Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '5258',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Hemlock Street',
        state: 'UX',
        zipcode: '80491869',
        latitude: '-8.345918',
        longitude: '-25.680415',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Nassau Avenue',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '5812',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Irving Place',
        state: 'UI',
        zipcode: '75935146',
        latitude: '17.467636',
        longitude: '10.282593',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'PENDING',
      online: false,
      segment: 'RODEOLOGY',
      taxId: '63286af29542a129fcc3a2b7',
      deliveryCenterGroupId: '63286af24d7b25b2ad205029',
      creatorId: 'ff8e6e68-0014-4c46-a1f0-0e3103cd800c',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: '2a179832-1d07-4e8c-a3ef-109b0429a8c0',
      displayName: 'Accel',
      legalName: 'Sawyer Romero Cooper',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      contacts: [
        {
          type: 'PHONE',
          value: '+(894) 581-3107',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@sequitur.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Hausman Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '3509',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Chester Street',
        state: 'OH',
        zipcode: '11383541',
        latitude: '-50.329349',
        longitude: '-155.746945',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Newkirk Placez',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '6230',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Kiely Place',
        state: 'RI',
        zipcode: '10362058',
        latitude: '-72.903203',
        longitude: '175.939475',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'ACTIVE',
      online: false,
      segment: 'AVENETRO',
      taxId: '63286af2ab5d20f79b0be816',
      deliveryCenterGroupId: '63286af29859841d49111cb4',
      creatorId: 'a2a0c653-2750-4f66-a0d3-77e25b568bc6',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: 'c1bac838-bbd3-4d5d-b393-3ca3c9af5f3c',
      displayName: 'Turnling',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      legalName: 'Carson Paul Bowers',
      contacts: [
        {
          type: 'PHONE',
          value: '+(950) 464-3379',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@telepark.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Roosevelt Court',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '2256',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Berkeley Place',
        state: 'MT',
        zipcode: '62177679',
        latitude: '-66.459268',
        longitude: '174.319532',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Karweg Place',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9631',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Varick Street',
        state: 'MS',
        zipcode: '66770923',
        latitude: '-31.135168',
        longitude: '-151.908692',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'INACTIVE',
      online: false,
      segment: 'FARMEX',
      taxId: '63286af2ddec84aa0414fdea',
      deliveryCenterGroupId: '63286af26d1032c7d8c7498d',
      creatorId: '07c0f906-e9d5-4db5-afd0-6296b68daf91',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
      timezone: 'America/Sao_Paulo',
    },
    {
      id: 'be137fbe-7e23-4889-adad-8935b42cd12a',
      displayName: 'Ersum',
      legalName: 'Chandler Key Pruitt',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      timezone: 'America/Sao_Paulo',
      contacts: [
        {
          type: 'PHONE',
          value: '+(882) 505-3865',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@satiance.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Tennis Court',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9016',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Olive Street',
        state: 'AK',
        zipcode: '24985219',
        latitude: '56.324997',
        longitude: '-101.862884',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Ruby Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '7284',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Fillmore Place',
        state: 'FL',
        zipcode: '84455637',
        latitude: '47.676578',
        longitude: '-19.51003',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'ACTIVE',
      online: true,
      segment: 'IZZBY',
      taxId: '63286af273cf1deb588c667d',
      deliveryCenterGroupId: '63286af202cc4b91ca5feb2d',
      creatorId: '7593232d-67af-4de0-9fa9-9c32afcfd99b',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
    },
    {
      id: '73835089-9cc2-4e2c-9782-4a1396385ae9',
      displayName: 'Quinex',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      legalName: 'Bates Shepard Greer',
      timezone: 'America/Sao_Paulo',
      contacts: [
        {
          type: 'PHONE',
          value: '+(842) 585-2209',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@cuizine.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Lynch Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '2923',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Utica Avenue',
        state: 'NJ',
        zipcode: '45760003',
        latitude: '-2.407309',
        longitude: '81.83427',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Ocean Court',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '2822',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Barbey Street',
        state: 'PS',
        zipcode: '10665588',
        latitude: '-36.677363',
        longitude: '110.573124',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'BLOCKED',
      online: false,
      segment: 'ZIALACTIC',
      taxId: '63286af22449a2969df6cb48',
      deliveryCenterGroupId: '63286af2b1fcf0affdf410fe',
      creatorId: '93b6dee4-8654-4513-b1f1-b848faf172d4',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
    },
    {
      id: '3091b23b-97ef-40a0-8cb5-c1cfdcd3081b',
      displayName: 'Flumbo',
      isDynamicCoverageAreaToggleOn: false,
      isScheduleEnable: false,
      timezone: 'America/Sao_Paulo',
      legalName: 'Mckinney Howell Andrews',
      contacts: [
        {
          type: 'PHONE',
          value: '+(857) 582-2630',
        },
        {
          type: 'EMAIL',
          value: 'longjoyner@urbanshee.com',
        },
      ],
      billingAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Jefferson Street',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '9507',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Norfolk Street',
        state: 'NV',
        zipcode: '13009974',
        latitude: '-75.429922',
        longitude: '71.738177',
      },
      deliveryAddress: {
        lines: [
          {
            type: 'THOROUGHFARE',
            value: 'Cass Place',
          },
          {
            type: 'COMPLEMENT',
            value: 'Apartment B',
          },
          {
            type: 'NUMBER',
            value: '6731',
          },
          {
            type: 'NEIGHBORHOOD',
            value: 'Chestnut Street District',
          },
          {
            type: 'REFERENCE',
            value: 'Next to the TA gas station',
          },
        ],
        city: 'Oceanview Avenue',
        state: 'NB',
        zipcode: '76351775',
        latitude: '24.425201',
        longitude: '169.364529',
      },
      additionalInformation: {
        issuesInvoices: true,
        refrigeratorCount: 'ZERO',
        hasColdRoom: false,
        hasServiceEntry: false,
      },
      coverageArea: [
        {
          name: 'Regular Coverage Area',
          active: true,
          coordinates: [
            [-89.20734317966023, 13.697989876149492],
            [-89.20651740290847, 13.698612165245962],
            [-89.20790014433915, 13.69880360897308],
            [-89.20734317966023, 13.697989876149492],
          ],
        },
        {
          name: 'Other Coverage Area',
          active: false,
          coordinates: [
            [-89.20975940476421, 13.695919261791161],
            [-89.2078104467376, 13.696235059167705],
            [-89.20871817543998, 13.696962291445502],
            [-89.21102975404781, 13.696527607763628],
            [-89.2112989248829, 13.695831779578938],
            [-89.20975940476421, 13.695919261791161],
          ],
        },
      ],
      businessHours: {
        sunday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        monday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        tuesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        wednesday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        thursday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        friday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
        saturday: [
          {
            openAt: '1970-01-01T09:00:00.000Z',
            closeAt: '1970-01-01T21:21:00.000Z',
          },
        ],
      },
      status: 'PENDING',
      online: false,
      segment: 'VERAQ',
      taxId: '63286af2cf1a923cb1439995',
      deliveryCenterGroupId: '63286af2a59014231f489d70',
      creatorId: 'f1f9dd20-60ec-4efa-805d-171255b41fd3',
      createdAt: '2022-08-17T21:08:34.202Z',
      updatedAt: '2022-08-17T21:08:34.202Z',
    },
  ],
  pagination: {
    page: 0,
    pageSize: 10,
    totalElements: 16,
    totalPages: 2,
  },
};

export const sellerListMockEmpty = {
  content: [],
  pagination: {
    page: 0,
    pageSize: 0,
    totalElements: 0,
    totalPages: 0,
  },
};

export const envConfig: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  supportedCountries: ['HN', 'SV', 'PA', 'BO'],
  segmentKey: '',
  vendorId: {
    AR: 'ID-AR',
    BO: 'ID-BO',
    BR: 'ID-BR',
    CO: 'ID-CO',
    DO: 'ID-DO',
    EC: 'ID-EC',
    HN: 'ID-HN',
    MX: 'ID-MX',
    PA: 'ID-PA',
    PE: 'ID-PE',
    PY: 'ID-PY',
    SV: 'ID-SV',
    ZA: 'ID-ZA',
  },
  az_maps_account_client_id: '',
  az_maps_account_primary_key: '',
  optimizely_key: '',
  google_maps_key: '',
};

export const newDefaultCoverageAreaUpdateVariablesMock = {
  name: 'DEFAULT_AREA',
  active: true,
  coordinates: [
    [-47.0616, -229063.9],
    [-47.0408088, -229063.9021852],
    [-47.0209263, -229063.9086455],
    [-47.0028215, -229063.9190983],
    [-46.9872855, -229063.9330869],
    [-46.9749975, -229063.95],
    [-46.9664943, -229063.9690983],
    [-46.9621478, -229063.9895472],
    [-46.9621478, -229064.0104528],
    [-46.9664943, -229064.0309017],
    [-46.9749975, -229064.05],
    [-46.9872855, -229064.0669131],
    [-47.0028215, -229064.0809017],
    [-47.0209263, -229064.0913545],
    [-47.0408088, -229064.0978148],
    [-47.0616, -229064.1],
    [-47.0823912, -229064.0978148],
    [-47.1022737, -229064.0913545],
    [-47.1203785, -229064.0809017],
    [-47.1359145, -229064.0669131],
    [-47.1482025, -229064.05],
    [-47.1567057, -229064.0309017],
    [-47.1610522, -229064.0104528],
    [-47.1610522, -229063.9895472],
    [-47.1567057, -229063.9690983],
    [-47.1482025, -229063.95],
    [-47.1359145, -229063.9330869],
    [-47.1203785, -229063.9190983],
    [-47.1022737, -229063.9086455],
    [-47.0823912, -229063.9021852],
    [-47.0616, -229063.9],
  ],
};

export const defaultCoverageAreaUpdateVariablesMock = {
  accountId: '4c08a2ec-ba63-4993-8c93-c62d3b14f7c6',
  sellerCoverageArea: {
    coverageArea: [
      {
        name: 'Other Coverage Area',
        active: false,
        coordinates: [
          [-89.20975940476421, 13.695919261791161],
          [-89.2078104467376, 13.696235059167705],
          [-89.20871817543998, 13.696962291445502],
          [-89.21102975404781, 13.696527607763628],
          [-89.2112989248829, 13.695831779578938],
          [-89.20975940476421, 13.695919261791161],
        ],
      },
      newDefaultCoverageAreaUpdateVariablesMock,
    ],
  },
};
